import {
  FETCH_APP_CONFIG_SUCCESS,
  FETCH_USER_CONFIG_SUCCESS,
} from '../actions/appActions';
import { FETCH_MOBILE_MODULE_CONFIG_SUCCESS } from '../actions/mobileModuleConfigAction';
import _ from 'lodash';
import { IconKeys } from '@axa-asia/emma-stylesheet/lib/components/EmmaAtoms/EmmaIcon';

const appInitState = {
  moduleConfig: {},
  deepLinkMapping: {},
  systemStatus: {},
  preVersion: ``,
  postVersion: ``,
  avators: {},
  isUserConfigLoaded: false,
};

type Feature = {
  id: string;
  icon: string;
  iconKey: IconKeys;
  label: {
    en: string;
    zh: string;
  };
  desc: {
    en: string;
    zh: string;
  };
  deepLink: any;
};

export type FeatureLimitedWebInfo = {
  hasLifePolicies: boolean;
  hasEbPolicies: boolean;
  lifePolicies: any[];
  ebPolicies: any[];
  ebAllowedWebFeatures: string[];
  alternativeEntries: Feature[];
  showLimitedWebPortalPolicySelectionBanner: boolean;
  hideLeftNavBar: boolean;
};

function featureLimitedWebPortalInfo(
  moduleConfig: any,
  consumerLogin: any,
): FeatureLimitedWebInfo {
  const lifeEntitycodes = ['LHK', 'LMA'];
  const ebEntitycodes = ['EHK'];

  const policies = consumerLogin.policies;

  const lifePolicies = policies.filter((p) =>
    lifeEntitycodes.includes(p.entityCode),
  );

  const ebPolicies = policies.filter((p) =>
    ebEntitycodes.includes(p.entityCode),
  );

  const hasLifePolicies = lifePolicies.length > 0;
  const hasEbPolicies = ebPolicies.length > 0;

  const ebFeatureEntries = _.get<Feature[]>(
    moduleConfig,
    'emma-eb-revamp.features',
    [],
  );
  // const allowedEbWebFeatures = ebFeatureEntries.map(f => f.id);

  const showLimitedWebPortalPolicySelectionBanner =
    hasLifePolicies && ebFeatureEntries.length > 0;

  const alternativeEntries = ebFeatureEntries;

  const hideLeftNavBar = !hasLifePolicies && hasEbPolicies;

  const ebAllowedWebFeatures = ebFeatureEntries.map((f) => f.id);

  return {
    hasLifePolicies,
    hasEbPolicies,
    lifePolicies,
    ebPolicies,
    alternativeEntries,
    showLimitedWebPortalPolicySelectionBanner,
    hideLeftNavBar,
    ebAllowedWebFeatures,
  };
}

export default (state: any = appInitState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_APP_CONFIG_SUCCESS:
      nextState.preVersion = action.config.preVersion;
      nextState.postVersion = action.config.postVersion;
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.config.deepLinkMapping,
      );
      nextState.systemStatus = _.merge(
        {},
        nextState.systemStatus,
        action.config.systemStatus,
      );
      nextState.avators = _.merge({}, nextState.avators, action.config.avators);
      return nextState;

    case FETCH_USER_CONFIG_SUCCESS:
      nextState.postVersion = action.config.postVersion;
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.config.deepLinkMapping,
      );
      nextState.systemStatus = _.merge(
        {},
        nextState.systemStatus,
        action.config.systemStatus,
      );
      nextState.avators = _.merge({}, nextState.avators, action.config.avators);
      nextState.isUserConfigLoaded = true;

      nextState.featureLimitedWeb = featureLimitedWebPortalInfo(
        nextState.moduleConfig,
        action.config.consumerLogin,
      );

      return nextState;

    case FETCH_MOBILE_MODULE_CONFIG_SUCCESS:
      nextState.moduleConfig = _.merge(
        {},
        nextState.moduleConfig,
        action.payload.config.moduleConfig,
      );
      nextState.deepLinkMapping = _.merge(
        {},
        nextState.deepLinkMapping,
        action.payload.config.deepLinkMapping,
      );
      nextState.isUserConfigLoaded = true;
      // We skip systemStatus and avators here. If need it, please add the field to app fetching logic.
      return nextState;
    default:
      return nextState;
  }
};
