import Moment from 'moment';
import _ from 'lodash';
import { resolveLocalizedText } from '../utils';
import { t } from '../../src/locales';
import { getRandom } from '../utils/randomNumUtil';

export function genKey() {
  return `${getRandom()}${Date.now()}`;
}
// date operation start
export function transferStringToDate(date) {
  const resultDate = Moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  return resultDate;
}

export function operateDate(date, addDays) {
  const resultDate = Moment(date, 'YYYY-MM-DD')
    .subtract(addDays, 'days')
    .format('YYYY-MM-DD');
  return resultDate;
}

/* eslint-disable */
export function setDateConfig() {
  var OriginalDate = Date,
    Timemachine = {
      timestamp: 0,
      tick: false,
      tickStartDate: null,
      keepTime: false,
      difference: 0,
      config: function (options) {
        this.timestamp =
          OriginalDate.parse(options.dateString) ||
          options.timestamp ||
          this.timestamp;
        this.difference = options.difference || this.difference;
        this.keepTime = options.keepTime || this.keepTime;
        this.tick = options.tick || this.tick;
        if (this.tick) {
          this.tickStartDate = new OriginalDate();
        }
        this._apply();
      },

      reset: function () {
        this.timestamp = 0;
        this.tick = false;
        this.tickStartDate = null;
        this.keepTime = false;
        this.difference = 0;
        Date = OriginalDate;
        Date.prototype = OriginalDate.prototype;
      },

      _apply: function () {
        var self = this;

        Date = function () {
          var date;
          if (self.keepTime) {
            date = new OriginalDate();
          } else if (arguments.length >= 1 && arguments.length <= 7) {
            date = new OriginalDate(...arguments);
          } else {
            date = new OriginalDate(self.timestamp);
          }
          if (arguments.length === 0) {
            var difference = self._getDifference();
            if (difference !== 0) {
              date = new OriginalDate(date.getTime() + difference);
            }
          }
          return date;
        };
        Date.prototype = OriginalDate.prototype;
        Date.now = function () {
          var timestamp = self.keepTime ? OriginalDate.now() : self.timestamp;
          return timestamp + self._getDifference();
        };
        Date.OriginalDate = OriginalDate;
        Date.UTC = OriginalDate.UTC;
      },
      _getDifference: function () {
        let difference = this.difference;
        if (this.tick) {
          difference += OriginalDate.now() - this.tickStartDate.getTime();
        }
        return difference;
      },
    };
  Timemachine._apply();
  return Timemachine;
}
// date operation end

export function convertPolicyCoverageData(data) {
  let convertedCoreBenefitData = convertCoreBenefitData(data);
  let convertedBenefitRemarksData = convertBenefitRemarksData(data.benefits);
  let convertedKeyExculsionsData = convertKeyExculsionsData(data.benefits);
  let convertedProductRemarksData = convertProductRemarksData(data.benefits);

  let policyCoverageTmp = {
    womanProductPolicies: [
      {
        label: data.planName,
        value: data.planCode,
      },
    ],
    policyNumber: data.policyNumber,
    entityCode: data.entityCode,
    planCode: data.planCode,
    planName: data.planName,
    coreBenefitList: convertedCoreBenefitData,
    benefitRemarks: convertedBenefitRemarksData,
    keyExculsions: convertedKeyExculsionsData,
    productRemarks: convertedProductRemarksData,
  };

  return policyCoverageTmp;
}

export function convertCoreBenefitData(initData) {
  let convertedData = [];
  let benefitsData = initData.benefits;
  let coreBenefitList = benefitsData.filter((item) => {
    return item.emmaCategory === "Benefit";
  });

  coreBenefitList.forEach((item) => {
    let benefitNamesTmp = [];
    let benefitRemarksTmp = [];
    let appointmentButtonDisableTmp = false;
    let textLinkUrlTmp = "";
    let coveragesTmp = [];

    let benefitNamesArr = item.benefitName.split("<sup>");
    let benefitNamesSuperscriptArr = item.benefitNameSuperscript
      ? item.benefitNameSuperscript.split(",")
      : [];

    benefitNamesArr.forEach((benefitName, index) => {
      benefitNamesTmp.push({
        value: benefitName,
        superscript: benefitNamesSuperscriptArr[index] || "",
      });
    });

    item.coverage &&
      item.coverage.forEach((coverageItem) => {
        coveragesTmp.push({
          label: coverageItem.coverageDescription || "",
          value: coverageItem.coverageValue || "",
        });

        if (
          coverageItem.benefitUtilization &&
          coverageItem.benefitUtilization === "Y"
        ) {
          coveragesTmp.push({
            label: t("WOMEN_PRODUCT_REMAINING_VISIT_TIME_LABEL"),
            value:
              parseInt(coverageItem.remainigUtilization) > 0
                ? coverageItem.remainigUtilization
                : "0",
          });

          if (parseInt(coverageItem.remainigUtilization) <= 0) {
            appointmentButtonDisableTmp = true;
          }
        }
      });

    let benefitRemarksArr = item.benefitRemark
      ? item.benefitRemark.split("<sup>")
      : [];
    let benefitRemarksSuperscriptArr = item.benefitRemarkSuperscript
      ? item.benefitRemarkSuperscript.split(",")
      : [];

    let benefitRemarksTempValue = "";
    benefitRemarksArr.forEach((benefitRemark, index) => {
      benefitRemarksTmp.push({
        value: benefitRemark,
        superscript: benefitRemarksSuperscriptArr[index] || "",
        tempValue: benefitRemark ? benefitRemark : benefitRemarksTempValue,
      });
      benefitRemarksTempValue = benefitRemarksTmp[index].value;
    });

    if (item.textType && item.textType === "Find.doctor") {
      textLinkUrlTmp = `emma-life/searchdoctor?policy_number=${initData.policyNumber}&entity_code=${initData.entityCode}&plan_code=${initData.planCode}&planCode=${initData.planCode}`;
    }

    let benefitTmp = {
      category: item.emmaCategory,
      benefitNames: benefitNamesTmp,
      benefitRemarks: benefitRemarksTmp,
      ordering: item.benefitOrdering,
      appointment: item.appointment,
      appointmentButtonDisable: appointmentButtonDisableTmp,
      textLink: item.textLink,
      textLinkContent: item.textLinkContent,
      textLinkUrl: textLinkUrlTmp,
      coverages: coveragesTmp,
      benefitCode: item.benefitCode,
      benefitUsedupMessage: item.benefitUsedupMessage
        ? item.benefitUsedupMessage
        : "",
      benefitType: item.benefitType,
    };

    convertedData.push(benefitTmp);
  });

  convertedData.sort((a, b) => Number(a.ordering) - Number(b.ordering));
  return convertedData;
}

export function convertBenefitRemarksData(initData) {
  let convertedData = {};

  let benefitRemarksList = initData.filter((item) => {
    return item.emmaCategory === "Benefit Remarks";
  });

  let benefitRemarks = "";
  let splitedBenefitRemarks = [];
  benefitRemarksList &&
    benefitRemarksList.forEach((item, index) => {
      benefitRemarks += `<p id='benefitRemarks${index + 1}'>
      <span>${index + 1}. </span>${item.remark}
    </p>`;
      splitedBenefitRemarks.push(item.remark);
    });

  const category =
    benefitRemarksList &&
    benefitRemarksList[0] &&
    benefitRemarksList[0].emmaCategory
      ? benefitRemarksList[0].emmaCategory
      : "";

  convertedData = {
    title: t("WOMEN_PRODUCT_REMARKS"),
    message: {
      en: benefitRemarks,
      zh: benefitRemarks,
    },
    splitedBenefitRemarks,
    okButton: {
      label: t("CLOSE"),
    },
    category: category,
  };
  return convertedData;
}

export function convertKeyExculsionsData(initData) {
  let convertedData = {};

  let keyExculsionsList = initData.filter((item) => {
    return item.emmaCategory === "Key Exclusions";
  });

  let keyExculsionsContentArr = [];
  keyExculsionsList.forEach((item) => {
    if (item.remarkType !== "Remark.name") {
      let keyExculsionsContentTmp = {
        type:
          item.remarkType === "Description.header"
            ? "header"
            : item.remarkType === "Description.subheader"
            ? "subheader"
            : "description",
        text: item.remark,
        superscript: item.remarkSuperScript ? item.remarkSuperScript : "",
      };

      keyExculsionsContentArr.push(keyExculsionsContentTmp);
    }
  });

  let keyExculsionsTitleItem = keyExculsionsList.find(
    (item) => item.remarkType === "Remark.name"
  );
  const category =
    keyExculsionsList &&
    keyExculsionsList[0] &&
    keyExculsionsList[0].emmaCategory
      ? keyExculsionsList[0].emmaCategory
      : "";

  convertedData = {
    category: category,
    title:
      !!keyExculsionsTitleItem && !!keyExculsionsTitleItem.remark
        ? keyExculsionsTitleItem.remark
        : "",
    content: keyExculsionsContentArr,
  };

  return convertedData;
}

export function convertProductRemarksData(initData) {
  let convertedData = {};

  let prouctRemarksList = initData.filter((item) => {
    return item.emmaCategory === "Product Remarks";
  });

  let prouctRemarksDescArr = [];
  prouctRemarksList.forEach((item) => {
    if (item.remarkType === "Description") {
      prouctRemarksDescArr.push(item.remark);
    }
  });

  let prouctRemarksTitleItem = prouctRemarksList.find(
    (item) => item.remarkType === "Remark.name"
  );
  let prouctRemarksSubtitleItem = prouctRemarksList.find(
    (item) => item.remarkType === "Subtitle"
  );
  const category =
    prouctRemarksList &&
    prouctRemarksList[0] &&
    prouctRemarksList[0].emmaCategory
      ? prouctRemarksList[0].emmaCategory
      : "";

  convertedData = {
    category: category,
    title:
      !!prouctRemarksTitleItem && !!prouctRemarksTitleItem.remark
        ? prouctRemarksTitleItem.remark
        : "",
    subtitle:
      !!prouctRemarksSubtitleItem && prouctRemarksSubtitleItem.remark
        ? prouctRemarksSubtitleItem.remark
        : "",
    descriptions: prouctRemarksDescArr,
  };

  return convertedData;
}

export function convertAppointmentContactData(initData) {
  let convertedData = {};
  let bodyContentTmp = [];

  initData.forEach((item) => {
    bodyContentTmp.push({
      label: item.name ? resolveLocalizedText(item.name) : "",
      value: item.number ? item.number : "",
      hotlineAndWhatsapp: item.hotlineAndWhatsapp ? item.hotlineAndWhatsapp : "",
      link: item.link ? resolveLocalizedText(item.link) : "",
      notes: item.notes ? resolveLocalizedText(item.notes) : "",
      email: item.email || "",
    });
  });

  const reservationBtn = initData.length && initData[0].reservationBtn;
  convertedData = {
    title: t("WOMEN_PRODUCT_MAKE_APPOINTMENT_TITLE"),
    subTitle: t("WOMEN_PRODUCT_MAKE_APPOINTMENT_SUB_TITLE"),
    bodyContent: bodyContentTmp,
    okButton: {
      label: t("CLOSE"),
    },
    reservationBtn,
  };

  return convertedData;
}

export function convertDependentsData(initData) {
  const data = _.get(initData, `retrieveDependentList.policy[0].dependent`, []);
  let returnData = [];
  data.forEach((item) => {
    returnData.push({
      optionTitle: item.englishName
        ? resolveLocalizedText(item.englishName)
        : "",
      optionVal: item.number ? item.number : "",
    });
  });
  return returnData;
}

export function convertEBCoreBenefitData(initData) {
  let convertedData = [];
  let benefitsData = initData.benefits;
  let coreBenefitList = benefitsData.filter((item) => {
    return item.emmaCategory === "Benefit Items";
  });

  coreBenefitList.forEach((item) => {
    let benefitNamesTmp = [];
    let benefitRemarksTmp = [];
    let appointmentButtonDisableTmp = false;
    let textLinkUrlTmp = "";
    let coveragesTmp = [];

    let benefitNamesArr = item.benefitName.split("<sup>");
    let benefitNamesSuperscriptArr = item.benefitNameSuperscript
      ? item.benefitNameSuperscript.split(",")
      : [];

    benefitNamesArr.forEach((benefitName, index) => {
      benefitNamesTmp.push({
        value: benefitName,
        superscript: benefitNamesSuperscriptArr[index] || "",
      });
    });

    item.coverage &&
      item.coverage.forEach((coverageItem) => {
        coveragesTmp.push({
          label: coverageItem.coverageDescription || "",
          value: coverageItem.coverageValue || "",
        });

        if (
          coverageItem.benefitUtilization &&
          coverageItem.benefitUtilization === "Y"
        ) {
          coveragesTmp.push({
            label: t("WOMEN_PRODUCT_REMAINING_VISIT_TIME_LABEL"),
            value:
              parseInt(coverageItem.remainigUtilization) > 0
                ? coverageItem.remainigUtilization
                : "0",
          });

          if (parseInt(coverageItem.remainigUtilization) <= 0) {
            appointmentButtonDisableTmp = true;
          }
        }
      });

    let benefitRemarksArr = item.benefitRemark
      ? item.benefitRemark.split("<sup>")
      : [];
    let benefitRemarksSuperscriptArr = item.benefitRemarkSuperscript
      ? item.benefitRemarkSuperscript.split(",")
      : [];

    benefitRemarksArr.forEach((benefitRemark, index) => {
      benefitRemarksTmp.push({
        value: benefitRemark,
        superscript: benefitRemarksSuperscriptArr[index] || "",
      });
    });

    if (item.textType && item.textType === "Find.doctor") {
      textLinkUrlTmp = `emma-life/search-doctor-life?policy_number=${initData.policyNumber}&entity_code=${initData.entityCode}&plan_code=${initData.planCode}`;
    }

    let benefitTmp = {
      category: item.emmaCategory,
      benefitNames: benefitNamesTmp,
      benefitRemarks: benefitRemarksTmp,
      ordering: Number(item.benefitSortOrder),
      appointment: item.appointment,
      appointmentButtonDisable: appointmentButtonDisableTmp,
      textLink: item.textLink,
      textLinkContent: item.textLinkContent,
      textLinkUrl: textLinkUrlTmp,
      coverages: coveragesTmp,
      benefitCode: item.benefitCode,
      benefitUsedupMessage: item.benefitUsedupMessage
        ? item.benefitUsedupMessage
        : "",
      benefitType: item.benefitTitle,
      benefitNameOrder: Number(item.benefitNameOrder),
    };

    convertedData.push(benefitTmp);
  });

  convertedData.sort((a, b) => a.ordering - b.ordering);
  return convertedData;
}

export function convertEBCoverageBenefitTabs(data) {
  let returnData = [];
  data.forEach((item) => {
    returnData.push(item.benefitType);
  });
  return _.uniq(returnData);
}

export function convertEBCoreBenefitList(benefitTabs, data) {
  let returnData = [];
  if (benefitTabs && benefitTabs.length > 0) {
    benefitTabs.forEach((item) => {
      const benefitList = _.filter(data, (e) => e.benefitType === item);
      const all = {
        optionTitle: `${t("WOMEN_PRODUCT_BENEFIT_DORPDOWN_ALL")} ${item} ${t(
          "WOMEN_PRODUCT_BENEFIT_DORPDOWN_BENEFITS"
        )}`,
        optionVal: "All",
      };
      const benefitDropDownList = [];
      benefitDropDownList.push(all);
      benefitList.forEach((listItem) => {
        const itemValue = _.get(listItem, "benefitNames[0].value", "");
        benefitDropDownList.push({
          optionTitle: itemValue,
          optionVal: itemValue,
        });
      });
      let benefitModel = {
        benefitType: item,
        initBenefitDropDownValue: all.optionVal,
        benefitDropDownList,
        benefitList,
      };
      returnData.push(benefitModel);
    });
  }
  return returnData;
}

export function convertPolicyEBCoverageData(data) {
  let returnData = [];
  data.forEach((item) => {
    let convertedCoreBenefitData = convertEBCoreBenefitData(item);
    convertedCoreBenefitData = convertedCoreBenefitData.sort((a, b) =>
      a.ordering != b.ordering
        ? a.ordering > b.ordering
          ? 1
          : -1
        : a.benefitNameOrder > b.benefitNameOrder
        ? 1
        : -1
    );
    let benefitTabs = convertEBCoverageBenefitTabs(convertedCoreBenefitData);
    let EBCoreBenefitList = convertEBCoreBenefitList(
      benefitTabs,
      convertedCoreBenefitData
    );
    let convertedBenefitRemarksData = convertBenefitRemarksData(item.benefits);
    let convertedKeyExculsionsData = convertKeyExculsionsData(item.benefits);
    let convertedProductRemarksData = convertProductRemarksData(item.benefits);

    let policyCoverageTmp = {
      initBenefitTab:
        benefitTabs && benefitTabs.length > 0 ? benefitTabs[0] : "",
      benefitTabs: benefitTabs,
      womanProductPolicies: [
        {
          label: item.planName || "",
          value: item.planCode || "",
        },
      ],
      policyNumber: item.policyNumber || "",
      entityCode: item.entityCode || "",
      planCode: item.planCode || "",
      planName: item.planName || "",
      certificateNumber: item.certificateNumber || "",
      dependentNumber: item.dependentNumber || "",
      benefitClass: item.benefitClass || "",
      coreBenefitList: convertedCoreBenefitData,
      benefitRemarks: convertedBenefitRemarksData,
      keyExculsions: convertedKeyExculsionsData,
      productRemarks: convertedProductRemarksData,
      EBCoreBenefitList,
      isTeleconsultationEnabled: item.isTeleconsultationEnabled || "",
    };
    returnData.push(policyCoverageTmp);
  });
  return returnData;
}

export const sequenceValidatorCheck = (s) => {
  // Check for sequential numerical characters
  // Should not allow more than six number sequence
  // eg: 1234567 is invalid
  // eg: 1234578 is valid
  // Code iterate through each number checking it with the next number (upto six)
  for (let i = 0; i < s.length; i++) {
    if (
      +s[i + 1] === +s[i] + 1 &&
      +s[i + 2] === +s[i] + 2 &&
      +s[+i + 3] === +s[i] + 3 &&
      +s[+i + 4] === +s[i] + 4 &&
      +s[+i + 5] === +s[i] + 5
    ) {
      return false;
    }
  }
  // Check for sequential alphabetical characters
  // Should not allow more than six alphabetical sequence
  // eg: abcdefgh is invalid
  // eg: abcdfghi is valid
  // Code iterate through each alphabet checking it with the next alphabet (upto six)
  const s1 = s.toUpperCase();
  for (let i = 0; i < s1.length; i++) {
    if (
      String.fromCharCode(s1.charCodeAt(i) + 1) === s1[+i + 1] &&
      String.fromCharCode(s1.charCodeAt(i) + 2) === s1[+i + 2] &&
      String.fromCharCode(s1.charCodeAt(i) + 3) === s1[+i + 3] &&
      String.fromCharCode(s1.charCodeAt(i) + 4) === s1[+i + 4] &&
      String.fromCharCode(s1.charCodeAt(i) + 5) === s1[+i + 5]
    ) {
      return false;
    }
  }
  return true;
};

export const passwordRequirements = (string) => {
  const obj = {
    isLength: string.length > 7 && string.length < 17,
    checkRepetition: /.*((.)\2{2}?(?!\2))/g.test(string),
    checkSpace: /(.*\s)/g.test(string),
    checkCharacter: 0,
    checkLowercase: /(.*[a-z])/g.test(string),
    checkUppercase: /(.*[A-Z])/g.test(string),
    checkDigit: /(.*\d)/g.test(string),
    checkSpecial: /(.*_|[^\w])/g.test(string),
    checkSequence: !sequenceValidatorCheck(string),
  };
  /(.*[A-Z])/g.test(string) ? obj.checkCharacter++ : null;
  /(.*[a-z])/g.test(string) ? obj.checkCharacter++ : null;
  /(.*\d)/g.test(string) ? obj.checkCharacter++ : null;
  /(.*_|[^\w])/g.test(string) ? obj.checkCharacter++ : null;
  return obj;
};

export const validatePassword = (
  value,
  opt,
  min,
  max,
  minMsg,
  maxMsg,
  errMsg
) => {
  let validationRequirements = {};
  if (value) {
    validationRequirements = passwordRequirements(value);
  }
  if (!value && !opt) return "INPUT_EMPTY_FIELD";
  if (value && /[\uff00-\uffff]/.test(value)) return "INPUT_INCORRECT_FORMAT";
  if (value && min && value.length < min)
    return minMsg || errMsg || "INPUT_INCORRECT_LENGTH";
  if (value && max && value.length > max)
    return minMsg || errMsg || "INPUT_INCORRECT_LENGTH";
  if (value && validationRequirements.checkSpace)
    return maxMsg || errMsg || "INPUT_INCORRECT_SPACE";
  if (value && validationRequirements.checkRepetition)
    return maxMsg || errMsg || "INPUT_INCORRECT_REPETITION";
  if (
    value &&
    validationRequirements.checkCharacter < 3 &&
    !validationRequirements.checkLowercase
  )
    return maxMsg || errMsg || "INPUT_INCORRECT_LOWER";
  if (
    value &&
    validationRequirements.checkCharacter < 3 &&
    !validationRequirements.checkDigit
  )
    return maxMsg || errMsg || "INPUT_INCORRECT_DIGIT";
  if (
    value &&
    validationRequirements.checkCharacter < 3 &&
    !validationRequirements.checkUppercase
  )
    return maxMsg || errMsg || "INPUT_INCORRECT_UPPER";
  if (value && validationRequirements.checkSequence)
    return errMsg || "INPUT_INCORRECT_SEQUENCE";
  return "";
};

export const validateRetypePassword = (value, password) => {
  if (!value) return "INPUT_EMPTY_FIELD";
  if (value && /[\uff00-\uffff]/.test(value)) return "INPUT_INCORRECT_FORMAT";
  if (value !== password) return "INPUT_INCORRECT_MATCH";
  return "";
};

export const extractCountryCode = (mobileNumber) => {
  if (!mobileNumber || !mobileNumber.length) {
    return {
      countryCode: "",
      mobileNumber,
    };
  } else if (mobileNumber.length === 8) {
    return {
      countryCode: "852",
      mobileNumber,
    };
  } else if (mobileNumber.length === 9 && mobileNumber.startsWith("9")) {
    return {
      countryCode: "886",
      mobileNumber,
    };
  } else if (mobileNumber.length === 10 && mobileNumber.startsWith("09")) {
    return {
      countryCode: "886",
      mobileNumber: mobileNumber.slice(1),
    };
  } else if (mobileNumber.length === 11 && mobileNumber.startsWith("852")) {
    return {
      countryCode: "852",
      mobileNumber: mobileNumber.slice(3),
    };
  } else if (mobileNumber.length === 11 && mobileNumber.startsWith("853")) {
    return {
      countryCode: "853",
      mobileNumber: mobileNumber.slice(3),
    };
  } else if (mobileNumber.length === 11 && mobileNumber.startsWith("1")) {
    return {
      countryCode: "86",
      mobileNumber,
    };
  } else if (mobileNumber.length === 12 && mobileNumber.startsWith("886")) {
    return {
      countryCode: "886",
      mobileNumber: mobileNumber.slice(3),
    };
  } else if (mobileNumber.length === 13 && mobileNumber.startsWith("86")) {
    return {
      countryCode: "86",
      mobileNumber: mobileNumber.slice(2),
    };
  }

  return {
    countryCode: "",
    mobileNumber,
  };
};

/**
 * Treat the policy as Life Insured-Only IF 
 * 1) LMA OR LHK AND
 * 2) Trustee flag is N AND
 * 3) Insured access has been granted AND
 * 4) Party role code contains INS AND
 * 5) Party role code doesn't contains OWN
 */
export const isLifeOnlyInsuredPolicy = (policy) => {
  const {
    entityCode,
    trusteeFlag,
    insuredAccessFlag,
    partyRoleCd = [],
  } = policy;
  const allowLobs = /^(LMA|LHK)/;
  return (
    allowLobs.test(entityCode) &&
    trusteeFlag === "N" &&
    insuredAccessFlag === "Y" &&
    partyRoleCd.includes("INS") &&
    !partyRoleCd.includes("OWN")
  );
};

/* Logic to display policy in policy list page owner section
 * 1.(Mobile) EB always display in insured section, FC always display in owner section,
 * these account don't have partyRoleCd and insuredAccessFlag at this moment
 * (Web portal) Won't display EB/FC policies
 * 2. Life owner-only and owner-insured account display in owner section
 * 3. Life insured-only authorized account display in insured section, if it doesn't have access, then we won't
 * display this policy
 * 4. Trustee always display in owner section and ignore the access checking
 * */
export const isOwnerPolicy = (policy) => {
  const { partyRoleCd = [], insuredAccessFlag, trusteeFlag } = policy;
  return (
    !isLifeOnlyInsuredPolicy(policy) &&
    // won't display insured policy without access unless its trustee
    (partyRoleCd.includes("INS") &&
    !partyRoleCd.includes("OWN") &&
    trusteeFlag !== "Y"
      ? insuredAccessFlag === "Y"
      : true)
  );
};

export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const keepTwoDecimal = (num) => {
  var result = parseFloat(num);
  result = Math.round(num * 100) / 100;
  return result;
};

export const RE_DIGIT = new RegExp(/^\d+$/);

const zeroPrefix = (n) => (n < 10 ? `0${n}` : n);

export const parseDateToString = (d: Date) => {
  return `${d.getFullYear()}-${zeroPrefix(d.getMonth() + 1)}-${zeroPrefix(
    d.getDate(),
  )}`;
};

export const isDateObjectValid = (d) => {
  if (d instanceof Date && !isNaN(d)) {
    return true;
  }
  return false;
}