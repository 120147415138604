import { memoSanitize } from '@axa-asia/emma-safely-set-inner-html';
import React, { Component } from 'react';
import './styles.scss';

import { connect } from 'react-redux';

import { t } from '../../../../locales';
import Header from '../../../../components/Header';
import { getSystemStatus } from '../../../../selectors/systemSelectors';

import { getLanguage } from '../../../../selectors/userSelectors';

import EBFlexiBannerView from '../../../../components/EBFlexiBanner/EBFlexiBannerView';

import EmmaModalView from '../../../../components/EmmaModalView/EmmaModalView';

import { EB_FELXI_FROM } from '../../../../constants/constants';

import { signOut } from '../../../../actions/authActions';
import { getToken } from '../../../../selectors/authSelectors';
import MaintenanceCommonScreen from '../../../../components/MaintenanceCommonScreen';
import { withLoadingWidgetHoc } from '../../../../components/lazyLoading/with-loading-widget-hoc';
import EBFlexOverlayView from '../../../../components/EBFlexiBanner/EBFlexOverlayView';
import { getFeatureLimitedWeb } from '../../../../selectors/configSelectors';
import { FeatureLimitedWebInfo } from '../../../../reducers/appReducer';
import { uniqBy } from 'lodash';
import { deepLinkFormat } from '../../../../utils/WebUtils';

import { goto } from '../../../../actions/historyActions';

import rootComponentRegistry from '../../../../registries/RootComponentRegistry';
import FrameContainer from '../../../../containers/FrameContainer';
import {
  EmmaFeatureEntryCard,
  EmmaSpace,
  EmmaText,
} from '@axa-asia/emma-stylesheet';
import classNames from 'classnames';

import browserHistory from '../../../../stores/browserHistory';

interface MaintenanceScreenProps {
  message: any;
  token: string;
  onSignOut: () => void;
  isCommonContent: boolean;
  commonContent: any;
  featureLimitedWeb: FeatureLimitedWebInfo;
}

const setScreen = (isBlocked: boolean) => {
  if (isBlocked) {
    rootComponentRegistry.set(ConnectedMaintenanceScreen);
  } else {
    rootComponentRegistry.set(FrameContainer);
  }
};

const ALLOWED_EB_URL = [
  '/emma/home/policy-list/healthcard',
  '/emma/home/eb/portal/e-health-card',
];

let currUrl;
let blockFeatureListener;

class MaintenanceScreen extends Component<MaintenanceScreenProps> {
  componentDidMount() {
    const { onSignOut } = this.props;
    setTimeout(() => {
      onSignOut();
    }, 15 * 60 * 1000);

    // If the maintenance screen is the entry point,
    // Need to check if user is allowed to access the page when url changes
    if (!blockFeatureListener) {
      blockFeatureListener = browserHistory.listen(() => {
        if (currUrl === location.href) return;
        currUrl = location.href;
  
        const isBlocked = !ALLOWED_EB_URL.some((url) =>
          location.pathname.includes(url),
        );
  
        setScreen(isBlocked);
      });
    }
  }

  render() {
    const {
      message,
      token,
      onSignOut,
      isCommonContent = false,
      commonContent,
    } = this.props;
    let content = message;

    if (!message) {
      return null; // fix async lifecycle issue
    }

    if (message.zh && message.en) {
      const lang = getLanguage();
      content = message[lang];
    }
    content = content.replace('openurl:', '');

    const lang = getLanguage();

    let commonContentModel = commonContent;
    if (commonContentModel && commonContentModel.zh && commonContentModel.en) {
      commonContentModel = commonContentModel[lang];
    }

    const alternativeEntries = this.props.featureLimitedWeb.alternativeEntries;
    const uniqueFeatureIds = uniqBy(alternativeEntries, 'id').map((f) => f.id);

    const hasAlternativeEntries = alternativeEntries.length > 0;

    const getDeepLink = (service: any) => {
      if (service.deepLink) {
        return service.deepLink.host;
      } else {
        return `emma-home/mypolicy?id=${service.id}`;
      }
    };

    return (
      <div className="App">
        <Header />
        <div>
          <EBFlexiBannerView type={EB_FELXI_FROM} />
          <EBFlexOverlayView type={EB_FELXI_FROM} />
        </div>
        {hasAlternativeEntries && (
          <div className="maintenance-screen-entries-container">
            <div
              className={classNames(
                EmmaText['PageTitle/Left/MineShaft'],
                EmmaSpace.mb24,
                EmmaSpace.pb4,
              )}
            >
              {lang === 'zh' ? '保險服務' : 'Insurance Services'}
            </div>
            <div className="d-flex">
              {uniqueFeatureIds.map((id) => {
                const f = alternativeEntries.find((f) => f.id === id);
                // const formattedDeeplink = deepLinkFormat(f.deepLink, lang);
                return (
                  <EmmaFeatureEntryCard
                    key={id}
                    icon={f.iconKey}
                    title={f.label?.[lang]}
                    description={f.desc?.[lang]}
                    onClick={() => {
                      goto(getDeepLink({ id }));
                      // rootComponentRegistry.set(FrameContainer);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
        {/* For EMMA WEB SYSTEM MAINTENANCE */}
        {!isCommonContent && (
          <div className="maintenance-screen">
            <div
              className="maintenance-screen__content"
              dangerouslySetInnerHTML={{ __html: memoSanitize(content) }}
            ></div>
            {!!token && (
              <div className="maintenance-screen__button-wrapper">
                <button
                  className="emma-stylesheet-primary-ghost-button"
                  onClick={onSignOut}
                  data-enable-ga
                  data-ga-type="button"
                >
                  {t(`LOGOUT`)}
                </button>
              </div>
            )}
          </div>
        )}
        {/* For Individual Life, Health, Savings & Investment Policyholders.*/}
        {isCommonContent && (
          <MaintenanceCommonScreen
            content={commonContentModel}
            onSignOut={onSignOut}
          />
        )}
        <EmmaModalView />
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      ...getSystemStatus(state),
      token: getToken(state),
      featureLimitedWeb: getFeatureLimitedWeb(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSignOut: () => dispatch(signOut()),
    };
  }
}

const ConnectedMaintenanceScreen = connect(
  MaintenanceScreen.mapStateToProps,
  MaintenanceScreen.mapDispatchToProps,
)(withLoadingWidgetHoc(MaintenanceScreen));

export default ConnectedMaintenanceScreen;
