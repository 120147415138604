import {
  ApplicationInsights,
  ICustomProperties,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

import Cookies from 'universal-cookie';

class AppInsightsClient {
  private static appInsightsClient: AppInsightsClient;
  private appInsights: ApplicationInsights;

  public init = (config: {
    instrumentationKey: string;
    axaId: string;
    ga4ClientId: string;
    app:
      | 'emma-web'
      | 'iam-fe'
      | 'life-fe'
      | 'eb-fe'
      | 'fc-fe'
      | 'emma-mobile'
      | 'prismic-fe';
    platform: 'app' | 'web-portal';
    disableConsoleLog: boolean;
    captureCaughtError: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extensions?: any[];
  }) => {
    const {
      instrumentationKey,
      axaId = '',
      ga4ClientId = '',
      app,
      platform,
      disableConsoleLog = false,
      captureCaughtError = true,
      extensions = [],
    } = config;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey, // SIT key
        accountId: axaId,
        /* ...Other Configuration Options... */
        extensions,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      envelope.data.ga4ClientId = ga4ClientId || this.getGa4ClientIdForWeb();
      envelope.data.platform = platform;
      envelope.data.app = app;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();

    if (disableConsoleLog) {
      this.disableConsoleLog();
    }
    if (captureCaughtError) {
      const enableConsoleOutput = !disableConsoleLog;
      this.captureCaughtError(enableConsoleOutput);
    }
  };

  /** Update account id after init */
  public updateAccountId(accountId: string): boolean {
    if (!this.checkInited()) return false;

    const telemetryInitializer = (envelope) => {
      envelope.tags['ai.user.accountId'] = accountId;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
    return true;
  }

  /** DEPRECATED GA3: Update GA Client Id */
  public updateClientId(clientId: string): boolean {
    if (!this.checkInited()) return false;

    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.data.clientId = clientId;
    });
    return true;
  }

  /** Update GA4 Client Id */
  public updateGa4ClientId(ga4ClientId: string): boolean {
    if (!this.checkInited()) return false;

    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.data.ga4ClientId = ga4ClientId;
    });
    return true;
  }

  private readBlob(data: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ({ target }) =>
        resolve(JSON.parse(target.result as string));
      reader.onerror = (error) => reject(error);
      reader.readAsText(data);
    });
  }

  /** Check if App Insights has been inited */
  private checkInited = (): boolean => {
    if (!this.appInsights) {
      console.warn(new Error('App Insight is not started yet.'));
      return false;
    }
    return true;
  };

  /** Try to get client id from GA for web */
  private getClientIdForWeb = (): string => {
    try {
      // @ts-ignore
      return window.ga.getAll()[0].get('clientId');
    } catch {
      return '';
    }
  };

  private getGa4ClientIdForWeb = (): string => {
    try {
      // @ts-ignore
      const cookies = new Cookies();
      const _ga = cookies.get('_ga') ?? '';
      return _ga?.replace(/^.*\.(?=.*\..*$)/g, '');
    } catch {
      return '';
    }
  };

  /** Call to disable console log, info and warn */
  private disableConsoleLog = () => {
    const logFunc = () => {
      /** do nothing  */
    };
    console.log = logFunc;
    console.info = logFunc;
    console.warn = logFunc;
  };

  /**
   * Call this to capture caught error from console.error
   * @param enableConsoleOutput
   */
  private captureCaughtError = (enableConsoleOutput: boolean) => {
    const overiddenLogFunc = (...objs) => {
      if (!objs || objs.length === 0) return;
      objs.forEach((v: any) => {
        if (v instanceof Error) {
          this.trackException(v);
        } else if (typeof v === 'string') {
          this.trackTrace(v);
        } else if (v instanceof Object) {
          this.trackTrace(JSON.stringify(v));
        } else {
          console.log(
            'App Insights Client:',
            'A unsupported type was found in console error',
            v,
          );
        }
      });

      // Output to console after capturing.
      if (enableConsoleOutput) {
        console.log(
          'App Insights Client:',
          'A caught error is logged to AppInsight:',
          ...objs,
        );
      }
    };

    /** Override Console Error */
    console.error = overiddenLogFunc;
  };

  public trackTrace = (message: string) => {
    if (!this.checkInited()) return;
    this.appInsights.trackTrace({ message });
  };

  public trackEvent = (eventName: string, props: ICustomProperties) => {
    if (!this.checkInited()) return;
    this.appInsights.trackEvent({ name: eventName }, props);
  };

  public startTrackEvent = (event: string) => {
    if (!this.checkInited()) return;
    this.appInsights.startTrackEvent(event);
  };

  public stopTrackEvent = (
    event: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
  ) => {
    if (!this.checkInited()) return;
    this.appInsights.stopTrackEvent(event, properties, measurements);
  };

  /**
   * Track CATCHED exception
   * Please note that App Insight can auto-track uncatched exception.
   * @param exception
   */
  public trackException = (exception: Error) => {
    if (!this.checkInited()) return;
    this.appInsights.trackException({ exception });
  };

  /** Return a singleton instance of AppInsightsClient */
  public static getInstance(): AppInsightsClient {
    if (!AppInsightsClient.appInsightsClient) {
      AppInsightsClient.appInsightsClient = new AppInsightsClient();
    }
    return AppInsightsClient.appInsightsClient;
  }
}

export { AppInsightsClient };
